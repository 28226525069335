import React, {useState} from "react";
import {
    Box,
    Paper,
    Typography,
    Divider,
    makeStyles,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import { getLogoImageUrl, generateSalesAnalysisUrl } from "../../../utils/utils";
import AddToCartButton from "../add-to-cart-button";
import AddToComparisonButton from "../add-to-comparison-button";
import Tooltip from "../tooltip";

import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoreIcon from '@material-ui/icons/Store';

import { IS_CART_ENABLED } from "../../../crud/constants";
import {useDispatch} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import Badge from "../badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import * as alert from "../../../store/ducks/alert.duck";
import * as popups from "../../../store/ducks/popups.duck";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        alignItems: "inherit",
        textAlign: "left",
        textTransform: "none",
        userSelect: "text",
        position: "relative"
    },

    paper: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    paperLocked: {
        filter: "grayscale(50%)",
    },

    paperLockOverlay: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        position: "absolute",
        backgroundColor: "rgba(241,241,241,0.53)",
        pointerEvents: "none"
    },

    logo: {
        width: "100px",
        height: "100px"
    },

    buttonsWrapper: {
        position: "absolute",
        right: "15px",
        top: "15px",
    },

    title: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "#262A40",
        textDecoration: "none",
    },

    titleFocused: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "#2E76F3",
        textDecoration: "underline",
    },

    text: {
        fontSize: "14px",
        color: "#7e83a0",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    icon: {
        width: "15px",
        height: "15px",
    },
}));

const prettifyUrl = (url) => {
    return url.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '').trim();
};

const prettifyPhoneNumber = (phoneNumber, country) => {
    let result = phoneNumber.trim();

    try {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country.code);
        result = parsedPhoneNumber.formatInternational();
    }
    catch (e) {
        //console.log(e);
    }

    return result;
};

const BrandCard = ({
    brandInfo,
    enableSalesAnalysisLink,
    useBrandLockStatus,
    showAddToCartButton,
    showAddToComparisonButton,
    month,
    ...props
 }) => {
    const { intl } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isFocused, setIsFocused] = useState(false);

    const SalesAnalysisLinkWrapper = ({children}) => {
        return (enableSalesAnalysisLink ? (
            <Link
                to={(!useBrandLockStatus || !brandInfo.isLocked) ? generateSalesAnalysisUrl({
                    brandId: brandInfo.id,
                    month: month
                }) : window.location.href.replace(window.location.origin, "")}
                onMouseEnter={() => { setIsFocused(true); }}
                onMouseLeave={() => { setIsFocused(false); }}
                onClick={(event) => {
                    if (useBrandLockStatus && brandInfo.isLocked) {
                        event.preventDefault();
                        dispatch(popups.actions.showSendMessagePopup(
                            "Brand access request (brand card)",
                            intl.formatMessage({ id: "REQUEST.ACCESS.POPUP.TITLE" }),
                            intl.formatMessage({ id: "REQUEST.BRAND.ACCESS.MESSAGE.TEMPLATE"}, { brandName: brandInfo.name }),
                        ));
                    }
                }}
            >
                {children}
            </Link>
        ) : (
            <>
                {children}
            </>
        ));
    };

    return (
        <Box className={classes.root}>
            <Paper elevation={!isFocused ? 5 : 20} className={clsx(
                {
                    [classes.paperLocked]: (useBrandLockStatus && brandInfo.isLocked)
                },
                classes.paper
            )}>
                <Box display="flex" flexDirection="column" flexGrow={1} height="100%">
                    <SalesAnalysisLinkWrapper>
                        <Box display="flex" flexDirection="column">
                            <Box p={3}>
                                <Box className={classes.logo}>
                                    <img
                                        src={getLogoImageUrl(brandInfo.logo)}
                                        alt={brandInfo.name}
                                        width="100%"
                                    />
                                </Box>

                                <Box className={classes.buttonsWrapper}>
                                    {IS_CART_ENABLED && showAddToCartButton && (
                                        <Box mb={1}>
                                            <AddToCartButton
                                                brandInfo={brandInfo}
                                                useButtonSimpleMode
                                            />
                                        </Box>
                                    )}
                                    {showAddToComparisonButton && (!useBrandLockStatus || !brandInfo.isLocked) && (
                                        <Box mb={1}>
                                            <AddToComparisonButton
                                                brandInfo={brandInfo}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Divider />

                            <Box p={3}>
                                <Typography className={!isFocused ? classes.title : classes.titleFocused}>
                                    {brandInfo.name}

                                    {(useBrandLockStatus && brandInfo.isLocked) && (
                                        <span style={{marginLeft: "8px"}}>
                                            <FontAwesomeIcon icon={faLock} />
                                        </span>
                                    )}
                                </Typography>

                                <Box mt={1}>
                                    <Typography className={classes.text}>{brandInfo.description}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </SalesAnalysisLinkWrapper>

                    <Box pl={3} pr={3} pb={3} mt={"auto"}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            {brandInfo.isPurchased && (
                                <Box mr={1}>
                                    <Badge color={"green"} text={<FormattedMessage id="BRAND.PURCHASE.BADGE.TEXT" />} />
                                </Box>
                            )}

                            {brandInfo.isDemo && (
                                <Box mr={1}>
                                    <Badge color={"green"} text={<FormattedMessage id="BRAND.DEMO.BADGE.TEXT" />} />
                                </Box>
                            )}
                        </Box>

                        <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                            {!!brandInfo.locationCount && (
                                <Tooltip arrow title={intl.formatMessage({id: "LOCATIONS.TOOLTIP"})}>
                                    <Box display="flex" flexDirection="row" alignItems="center" mr={1.5}>
                                        <Box mr={0.5}>
                                            <LocationOnIcon className={classes.icon} color="primary" />
                                        </Box>
                                        <Typography className={classes.text}>{brandInfo.locationCount}</Typography>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>

                        {brandInfo.site && (
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box mr={0.5}>
                                    <LinkIcon className={classes.icon} color="primary" />
                                </Box>
                                <Typography className={classes.text}><Link to="/#" onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();

                                    navigator.clipboard.writeText(brandInfo.site);
                                    dispatch(alert.actions.showAlert({
                                        severity: "success",
                                        message: intl.formatMessage({id: "COPIED_TO_CLIPBOARD"})
                                    }));
                                }}>{prettifyUrl(brandInfo.site)}</Link></Typography>
                            </Box>
                        )}

                        {brandInfo.phone && (
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Box mr={0.5}>
                                    <PhoneIcon className={classes.icon} color="primary" />
                                </Box>
                                <Typography className={classes.text}><Link to="/#" onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();

                                    navigator.clipboard.writeText(prettifyPhoneNumber(brandInfo.phone, brandInfo.country));
                                    dispatch(alert.actions.showAlert({
                                        severity: "success",
                                        message: intl.formatMessage({id: "COPIED_TO_CLIPBOARD"})
                                    }));
                                }}>{prettifyPhoneNumber(brandInfo.phone, brandInfo.country)}</Link></Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                {(useBrandLockStatus && brandInfo.isLocked) && (
                    <Box className={classes.paperLockOverlay} />
                )}
            </Paper>
        </Box>
    );
};

export default injectIntl(BrandCard);
